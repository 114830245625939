<template>
  <CRow>
    <CCol col="12" xl="12">
      
      <CCard>
        <CCardHeader>
          <div class="row align-items-center">
            <div class="col-6">
              <CHeaderNav>
                <CHeaderNavItem class="px-3">
                  <button
                    class="c-header-nav-btn"
                    @click="openSidebarEvent('careerApplicantFilter')"
                  >
                    <CIcon
                      v-if="!loading"
                      size="lg"
                      name="cil-filter"
                      class="mr-2"
                    />
                  </button>
                </CHeaderNavItem>
                <CDropdown toggler-text="Toplu İşlemler" color="warning" class="m-1">
                    <div role="group">
                    
                      <!--
                        Mail gönderim işlemi olan istekler toplu olarak gitmeyecek
                         <CDropdownItem 
                          :disabled="selectedApplicants.length === 0" 
                          @click="changeAllStatus('in_review')"> 
                          Seçili Başvuruları Değerlendirmede Olarak İşaretle 
                        </CDropdownItem>

                         <CDropdownItem 
                          :disabled="selectedApplicants.length === 0" 
                          @click="changeAllStatus('negative')"> 
                          Seçili Başvuruları Olumsuz Olarak İşaretle 
                        </CDropdownItem>

                         <CDropdownItem 
                          :disabled="selectedApplicants.length === 0" 
                          @click="changeAllStatus('negative_interview')"> 
                          Seçili Başvuruları Mülakat Olumsuz Olarak İşaretle 
                        </CDropdownItem>


                      -->
                     

                      <CDropdownItem 
                        :disabled="selectedApplicants.length === 0" 
                        @click="changeAllStatus('reviewed')"> 
                        Seçili Başvuruları İncelendi Olarak İşaretle 
                      </CDropdownItem>

                      <CDropdownItem 
                        :disabled="selectedApplicants.length === 0"
                        @click="deleteAll"> Seçili Başvuruları Kalıcı Olarak Sil 
                      </CDropdownItem>
                      
                    </div>
                </CDropdown>
              </CHeaderNav>
             
            </div>
            <div class="col-6 text-right">
             
            </div>
          </div>
        </CCardHeader>
      
        <CCardBody>
          <CDataTable
            :items="applicants"
            :fields="fields"
            :items-per-page="10"
            hover
            pagination
            :loading="loading"
            columnFilter
            clickable-rows
            @row-clicked="rowClicked"
            @row-double-clicked="openModalEvent('detail','careerApplicantForm', lastItem, 'Başvuru Detayı ( '+ lastItem.name+' )')"
            v-on:refresh="filterCareerApplicants(filterParams)"
          >

            <template #select-header>
              <th style="vertical-align: middle; overflow: hidden; font-size: 12px;"> {{selectedApplicants !== null ? selectedApplicants.length : 0}}_Adet</th>
            </template>

            <template #select="{item,index}">
              <td style="display: flex; justify-content: center;">
                <CInputCheckbox
                    :checked="item._selected"
                    @update:checked="() => check(item,index)"
                    custom
                />
              </td>
            </template>

            <template #show_details="{item}">
              <td class="py-2">
                <CDropdown
                  toggler-text="İşlemler"
                  class="m-2"
                  color="info"
                  size="sm"
                >
                  <CDropdownItem @click="openModalEvent('detail','careerApplicantForm', lastItem, 'Başvuru Detayı ( '+ lastItem.name+' )')">
                    Detay
                  </CDropdownItem>
                  <CDropdownItem @click="openModalEvent('changeStatus','applicantChangeStatusForm', lastItem, 'Başvuru Durumunu Değiştir ( '+ lastItem.name+' )')">
                    Başvuru Durumunu Değiştir
                  </CDropdownItem>
                  <CDropdownItem 
                    @click="openModalEvent('delete','confirm', item, 'Başvuru Silme Ekranı ('+ item.name+')', item.name+' isimli başvuruyu kalıcı olarak silmek üzeresiniz bunu yapmak istediğinizden emin misiniz?')">
                    Başvuruyu Sil
                  </CDropdownItem>
                </CDropdown>
              </td>
            </template>

            <template #createTime="{item}">
              <td> {{ item.createTime && dateFormat(item.createTime) }} </td>
            </template>

            <template #status="{item}">
              <td v-if="statusTypes.length > 0 && item.status !== ''">

                <CBadge 
                  v-if="statusTypes.find(s => item.status == s.type)"
                  :color="statusTypes.find(s => item.status == s.type).color"
                > 
                  {{ statusTypes.find(s => item.status == s.type).friendlyType }}
                </CBadge> 

                <p v-else> {{ item.status }} </p>

              </td>
            </template>

            <template #careerTitle="{item}">
              <td>
                {{ item.careerTitle ? item.careerTitle : 'Genel Başvuru'}}
              </td>
            </template>

          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <ConfirmModal
      v-if="form == 'confirm'" 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :confirm="crud"
      :desc="modalDesc"
      :data="modalProps"
      :actionType="actionType"
      :loading="loading"
      :size="size"
    />
    <FormModal 
      v-else
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :operationEvent="crud"
      :actionType="actionType"
      :module="module"
      :form="form"
      :data="modalProps"
      :loading="loading"
      size="lg"
      ref="form"
    />
    <FilterSidebar 
      :show="openSidebar" 
      :openSidebarEvent="openSidebarEvent"
      :operationEvent="filterCareerApplicants"
      :module="module"
      :form="filterForm"
      :data="filterParams"
      :loading="loading"
    />
  </CRow>
</template>
<script>

import FormModal from '../components/formModal'
import ConfirmModal from '../components/confirmModal'
import FilterSidebar from '../components/filterSidebar'
import moment from 'moment'
import { prepareFilterParams } from "@/helpers/custom"


export default {
  name: 'CareerApplicants',
  components: {
     ConfirmModal, FormModal, FilterSidebar
  },
  data() {
    return {
      modalTitle: '',
      actionType: '',
      module: 'hr',
      form: 'careerApplicantForm',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      openModalConfirm: false,
      lastPage: {},
      openSidebar: false,
      filterForm: 'careerApplicantFilter',
      filterParams: {},
      lastItem: {},
      size: 'sm',
      selectedApplicants: [],
      selectedApplicant: {},
      fields: [
        { key: 'select', label: '', filter: false},
        {key: 'show_details', label: 'İşlemler', filter: false},
        {key: 'createTime', label: 'Başvuru Tarihi', filter: false},
        {key: 'name', label: 'İsim', filter: false},
        {key: 'phoneNumber', label: 'Telefon', filter: false},
        {key: 'emailAddress', label: 'E-mail', filter: false},
        {key: 'careerTitle', label: 'İş İlanı', filter: false},
        {key: 'status', label: 'Durum', filter:false},
      ],
      activePage: 1,
      pages: 0,
      filters: [{
        type: 'string',
        dataIndex: 'careerPosition',
        field: 'careerPositionId'
      },{
        type: 'string',
        dataIndex: 'career',
        field: 'careerId'
      }, {
        type: 'string',
        dataIndex: 'name',
        field: 'name'
      },{
        type: 'string',
        dataIndex: 'emailAddress',
        field: 'emailAddress'
      },{
        type: 'string',
        dataIndex: 'phoneNumber',
        field: 'phoneNumber'
      },{
        type: 'string',
        dataIndex: 'coverLetter',
        field: 'coverLetter'
      },{
        type: 'string',
        dataIndex: 'status',
        field: 'status'
      },{
        type: 'string',
        dataIndex: 'note',
        field: 'note'
      },{
        type: 'date',
        dataIndex: 'startTime',
        field: 'createTime',
        comparison: 'gt'
      },{
        type: 'date',
        dataIndex: 'endTime',
        field: 'createTime',
        comparison: 'lt',
      },
    ]
    }
  },
  computed: {
    applicants(){
      return this.$store.getters.careerApplicants;
    },
    statusTypes(){
      return this.$store.getters.careerStatusTypes;
    },
    loading(){
      return this.$store.getters.careerLoading;
    }
  },
  methods: {
    openModalEvent(actionType, form, data, title, desc){
      this.confirmSize = 'sm'
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },

    async check(item, index) {
      let tmpSelected = []
      this.selectedApplicant = {}
      if(index > -1){
        const val = Boolean(this.applicants[index]._selected)
        this.$set(this.applicants[index], '_selected', !val)
        this.$set(this.applicants[index], '_classes', !val ? 'selected' : '');
        await this.applicants.map( u => {
          if (u._selected) {
           tmpSelected.push(u)
          }
        });
        this.selectedApplicant =tmpSelected[0];
      }
      this.selectedApplicants = [...tmpSelected]
    },

    openSidebarEvent(form){
      this.openSidebar = !this.openSidebar
      this.filterForm = form
    },

    async filterCareerApplicants(params) {
      this.filterParams = params
      let formData = prepareFilterParams(params, this.filters)
      
      await this.$store.dispatch('careerApplicants_list', formData)
      
      if(this.openSidebar){
        await this.openSidebarEvent()
      }
    },

    dateFormat(data){
      return moment(data).format('DD.MM.YYYY')
    },

    rowClicked(item, index, column) {
      this.lastItem = item;
      if (column !== "select") {

        this.selectedApplicants = [];
        this.selectedApplicant = {};

        for(var i = 0; i <this.applicants.length; i++){
          this.$set(this.applicants[i], '_selected', false);
          this.$set(this.applicants[i], '_classes', '');
        }

        const val = Boolean(this.applicants.find(e => e.id == item.id)._selected);
        this.$set(this.applicants.find(e => e.id == item.id), '_selected', !val);
        this.$set(this.applicants.find(e => e.id == item.id), '_classes', !val ? 'selected' : '');
        this.applicants.map(u => {
          if (u._selected) {
            this.selectedApplicants.push(u);
          }
        });
        this.selectedApplicant = this.selectedApplicants[0];
      } 
    },

    deleteAll: function(){
      let names = this.selectedApplicants.map(a => a.name);
      let msg = '<strong> '+names+'</strong> isimli kişilerin başvurularını kalıcı olarak silmek üzeresiniz bunu yapmak istediğinizden emin misiniz?';
      this.openModalEvent('deleteAll','confirm', this.selectedApplicants, 'Toplu Başvuru Silme Ekranı', msg)
    },

    changeAllStatus: function(status){
      let names = this.selectedApplicants.map(a => a.name);
      let msg = '<strong> '+names+'</strong> isimli kişilerin başvurularını <strong> '+this.statusTypes.find(s => s.type == status).friendlyType+' </strong> '+
      'olarak değiştirmek üzeresiniz. Bu işlem sonucunda başvuru yapan adaylara otomatik bilgilendirme maili gönderilebilir. İşlemi yapmak istediğinizden emin misiniz?';
      this.openModalEvent('changeAll','confirm', status, 'Toplu Başvuru Güncelleme Ekranı', msg)
    },
    async crud(item, actionType) {
      var formData = new FormData();
     if(actionType == 'delete'){

        formData.append('ids',item.id ? item.id : '')
        await this.$store.dispatch('careerApplicants_delete', formData)

    }else if(actionType == 'deleteAll'){

      formData.append('ids',  this.selectedApplicants.map(a => a.id))
      await this.$store.dispatch('careerApplicants_delete', formData)

    } else if(actionType == 'changeAll'){
      
      formData.append('ids',  this.selectedApplicants.map(a => a.id))
      formData.append('status', item )
      await this.$store.dispatch('careerApplicants_changeAll', formData)

    }else if(actionType == 'changeStatus'){

      console.log(item)

      formData.append('ids',  [item.id])
      formData.append('status', item.status )
      formData.append('lang', item.lang)
      formData.append('content', item.content ? item.content : '' )

      await this.$store.dispatch('careerApplicants_changeAll', formData)

    }else{

        formData.append('id',item.id ? item.id : '')
        formData.append('emailAddress',item.emailAddress)
        formData.append('phoneNumber', item.phoneNumber)
        formData.append('note',item.note)

        await this.$store.dispatch('careerApplicants_update', formData)

      }
       
      if(this.$store.getters.careerStatus.success) {
          await this.filterCareerApplicants(this.filterParams);
          this.openModalEvent()
      }
    }
  },
  async created() {

    if (this.$route.query.careerId !== null && this.$route.query.careerId !== undefined) {
      this.filterParams = {
        career: parseInt(this.$route.query.careerId),
      }
    }
    
    await this.$store.dispatch('careerStatusType_list')
    await this.$store.dispatch('careerPosition_list')
    await this.$store.dispatch('jobPosting_list')
    await this.$store.dispatch('lang_list')
    await this.filterCareerApplicants(this.filterParams);

  }
}
</script>
